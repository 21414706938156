<template>
  <section ref="m_common" :class="`m_common detect_deviece_${deviceType} state_${deviceType}`">
    <HeadBar :title="title" :deviceType="deviceType" @go-back="goback" />
    <slot />
    <FootBar :deviceType="deviceType" />
  </section>
</template>

<script>
import HeadBar from '@/pages/components/back_head.vue';
import FootBar from '@/pages/components/footer.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    HeadBar,
    FootBar
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters([
      'deviceType',
      'host',
    ])
  },
  methods: {
    goback () {
      this.$router.go(-1);
    }
  },

};
</script>