<template>
  <div class="nav-bar-row">
    <div class="back" @click="goback">
      <img src="@/assets/nav-back.png" alt="">
    </div>
    <div class="nav-title">
      {{ title }}
    </div>
  </div>
</template>

<script>
import '@/css/nav_bar.scss';

export default {
  props: {
    title: {
      type: String,
      required: true
    }
  },
  methods: {
    goback () {
      this.$emit('go-back');
    }
  }
}
</script>
